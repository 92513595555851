var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(2),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(3),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/Framex5747-11a9jgswvkca.png","alt":""}})])])]),_c('div',[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(4),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(5),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/Framex5755-17z5pxor9zm4.png","alt":""}})])])]),_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(6),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(7),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/蔚美切图03-iv6fj08rd7rh.png","alt":""}})])])]),_c('div',[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(8),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(9),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/Framex5756-w8o7tmore9zh.png","alt":""}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topImg"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/蔚美-dnz3c45njw5a.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"wrapper_1200 dom1"},[_c('p',{staticClass:"title"},[_vm._v("蔚美SaaS")]),_c('p',{staticClass:"titleText"},[_c('span',[_vm._v("该平台围绕民营口腔诊所业务场景；其通过数字化技术的应用，帮助口腔医疗机构实现从患者预约到就诊完成全流程数字化管理，帮助口腔医疗机构提高工作效率，提升患者体验，从而提升口腔医疗机构的品牌形象和市场竞争力。")]),_c('span',{staticClass:"icon1"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vector-821c0ssjruuc.png","alt":""}})]),_c('span',{staticClass:"icon2"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vectorxx1x-4dstvlpteupa.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能一")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能二")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能三")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能四")]),_c('span')])
}]

export { render, staticRenderFns }